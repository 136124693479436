import { AuthRedirect } from '../auth';
import { CallToAction } from './CallToAction';
import Faq2 from './Faq2';
import { Faqs } from './Faqs';
import { Hero } from './Hero';
import { Pricing } from './Pricing';
import { PrimaryFeatures } from './PrimaryFeatures';
import { PrimaryFeatures2 } from './PrimaryFeatures2';
import { PrimaryFeaturesNav } from './PrimaryFeaturesNav';
import SecondaryFeaturesA from './SecondaryFeaturesA';
import { Testimonials } from './Testimonials';

export const LandingPage = () => {
  return (
    <>
      <AuthRedirect />
      <Hero />
      <PrimaryFeatures />
      <PrimaryFeatures2 />
      {/* <PrimaryFeaturesNav /> */}
      <SecondaryFeaturesA />
      <CallToAction />
      <Faq2 />
      {/* <Testimonials />
      <Pricing /> */}
    </>
  );
};
