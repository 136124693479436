import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { Container } from '@components/Container';
import backgroundImage from 'composeiq/images/background-features.jpg';
import screenshotRewrite from 'composeiq/images/screenshots/rewrite.png';
import screenshotRecommendTopics from 'composeiq/images/screenshots/recommend-topics.png';
import screenshotContinueWriting from 'composeiq/images/screenshots/continue-writing.png';
import screenshotImageRecommendation from 'composeiq/images/screenshots/image-recommendation.png';
import screenshotFirstDraft from 'composeiq/images/screenshots/first-draft.png';
import chat from 'composeiq/images/screenshots/chat.png';

const features = [
  {
    title: 'Let AI Craft Your First Draft',
    description:
      'No more staring at a blank page. Have your first draft created swiftly and accurately by AI, conserving your valuable time and energy.',
    image: screenshotFirstDraft,
  },
  {
    title: 'Intelligent Chatbot: Your Personal Writing Assistant',
    description:
      'Elevate your writing journey with our AI-enabled chatbot, here to assist with detailed research and answer any queries about your document.',
    image: chat,
  },
  {
    title: 'Topic Suggestions at Your Fingertips',
    description: `Eliminate writer's block. Our document editor can suggest topics and even write content for you.`,
    image: screenshotRecommendTopics,
  },
  {
    title: 'Elevate Your Content with AI Rewriting',
    description:
      'Transform your writing and take it to the next level. Our AI-powered document editor can help you refine your content with just a few clicks.',
    image: screenshotRewrite,
  },
  {
    title: 'Keep the Momentum with AI',
    description:
      'Keep your ideas flowing. Our document editor uses AI to write next sentence, paragraph, code or list for helping you write faster and more efficiently.',
    image: screenshotContinueWriting,
  },
  {
    title: 'AI-Powered Image Suggestions',
    description:
      'Struggling to find the perfect image for your document? Let our AI-powered editor suggest the best images for your content.',
    image: screenshotImageRecommendation,
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState('horizontal');

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)');

    function onMediaQueryChange({
      matches,
    }: MediaQueryListEvent | MediaQueryList) {
      setTabOrientation(matches ? 'vertical' : 'horizontal');
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener('change', onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden pb-28 pt-20 sm:py-12"
    >
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-primary sm:text-4xl md:text-5xl">
            Unleash Your Writing Potential.
          </h2>
          <p className="mt-6 text-lg tracking-tight">
            Elevate your writing experience, fuel your productivity, and take
            your content to new heights with our AI-driven document editor.
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-0 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-6 lg:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 max-md:px-4 max-sm:px-2 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-2 gap-x-4 px-4 max-lg:flex-wrap max-lg:items-center max-lg:justify-center sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'group relative mt-2 rounded-full px-4 py-1 lg:rounded-xl lg:p-3 xl:p-4',
                        selectedIndex === featureIndex
                          ? 'bg-primary/10 lg:bg-primary/10 lg:ring-1 lg:ring-inset lg:ring-primary/10'
                          : 'hover:bg-primary/10 lg:hover:bg-primary/5'
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            'font-display text-lg outline-none focus:outline-none md:text-left',
                            selectedIndex === featureIndex
                              ? 'text-primary-600 lg:text-primary'
                              : 'text-primary-100 hover:text-primary lg:text-primary'
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-sm',
                          selectedIndex === featureIndex
                            ? 'text-secondary lg:block' //move lg:block outside of if check
                            : 'text-secondary-100 group-hover:text-secondary'
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <p className="relative mx-auto max-w-2xl text-base sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mx-auto mt-10 w-[20rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[35rem]">
                      <div className="border bg-base-300">
                        <div className="flex justify-center bg-base-200">
                          <Image
                            className="w-full"
                            src={feature.image}
                            alt=""
                            priority
                          />
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  );
}
