import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { Container } from '@components/Container';
import backgroundImage from 'composeiq/images/background-features.jpg';
import seoOne from 'composeiq/images/screenshots/seo-one.png';
import seoTwo from 'composeiq/images/screenshots/seo-two.png';
import seoThree from 'composeiq/images/screenshots/seo-three.png';

const features = [
  {
    title: 'Drive Traffic with SEO-Optimized Content Analysis',
    description:
      'Dive into the world of ready-to-rank content and watch as comprehensive SEO content summaries and SERP analysis become your game changers​.',
    image: seoOne,
  },
  {
    title: 'Explore Top Keywords to Elevate your SEO Game',
    description:
      "Don't let SEO goals feel out of reach. Our Writing Assistant's Keyword Explorer keeps you updated with real-time status of top keywords.",
    image: seoTwo,
  },
  {
    title: 'Leverage AI for Keyword Content Generation',
    description:
      "Generate top-quality, well-optimized content using our Writing Assistant's AI capabilities.",
    image: seoThree,
  },
];

export function PrimaryFeatures2() {
  let [tabOrientation, setTabOrientation] = useState('horizontal');

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)');

    function onMediaQueryChange({
      matches,
    }: MediaQueryListEvent | MediaQueryList) {
      setTabOrientation(matches ? 'vertical' : 'horizontal');
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener('change', onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-blue-600 pb-28 pt-20 sm:py-32"
    >
      <Image
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
        unoptimized
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Boost Your Online Visibility with SEO-Driven Marketing
          </h2>
          <p className="mt-6 text-lg tracking-tight text-blue-100">
            Elevate your marketing game by integrating SEO into your strategy.
          </p>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-x-12 gap-y-12 md:mt-8 md:grid-cols-2 lg:pt-0 xl:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.title} className="mx-auto my-0 w-full">
              <Image
                className="w-auto rounded-lg border-transparent shadow-md"
                src={feature.image}
                alt={feature.title}
                loading="lazy"
              />
              <div>
                <h3 className="pt-4 font-display text-lg text-white outline-none focus:outline-none ">
                  {feature.title}
                </h3>
                <p className="pt-2 font-display text-sm text-blue-100">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
