import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';

const faqs = [
  {
    question: 'What is Compose IQ and how can it help me with my writing?',
    answer:
      'Compose IQ is an advanced AI-powered writing assistant designed to boost your productivity and enhance your writing. It offers features such as AI-driven draft generation, topic suggestions, content rewriting, continuous writing assistance, and even image suggestions for your documents​.',
  },
  {
    question: 'How does the AI-powered draft generation feature work?',
    answer:
      "Compose IQ's AI can generate a first draft of your document quickly and accurately, saving you time and effort. You just need to provide some initial input or a brief, and the AI takes over from there, providing a comprehensive first draft​.",
  },
  {
    question:
      'Can I collaborate with my team on the same document using Compose IQ?',
    answer:
      'Yes, Compose IQ supports collaborative editing, allowing multiple people to work on the same document with real-time syncing. This makes it a powerful tool for team projects and collaborative endeavors?',
  },
  {
    question:
      'Does Compose IQ support any special writing or formatting features?',
    answer:
      "Indeed, Compose IQ's feature-rich document editor lets you write code with syntax highlighting and even create math equations and diagrams. It's also a block-based editor that supports keyboard shortcuts, enabling you to perform a variety of actions and maintain the flow of your writing. Additionally, it has a dark mode for a distraction-free writing experience​.",
  },
  {
    question:
      "What if the AI-generated content doesn't meet my expectations or needs some revisions?",
    answer:
      "Compose IQ not only helps generate content but also aids in refining it. You can use the tool to easily make revisions until the content meets your satisfaction. Remember, the AI is there to help expedite and ease your writing process, but it's your inputs and edits that truly make the content your own.",
  },
  {
    question: 'Can I use Compose IQ for professional or academic writing?',
    answer:
      "Yes, Compose IQ is versatile and can be used for a variety of writing tasks, from professional reports to academic essays. It's AI-powered editor analyzes your content for readability and keywords, helping you create the best possible document.",
  },
  {
    question:
      "I've never used an AI writing assistant before. Is Compose IQ easy to use?",
    answer:
      "Absolutely! Compose IQ is designed to be user-friendly and intuitive, even for those who have never used an AI writing assistant before. With straightforward features and a clean interface, you'll be able to start improving your writing in no time.",
  },
  {
    question: 'Can Compose IQ help me find the perfect image for my document?',
    answer:
      'Absolutely! Compose IQ includes an AI-powered feature that suggests the best images to complement your content. This takes the guesswork out of finding suitable visuals for your documents.',
  },
];

export default function Faq2() {
  return (
    <section className="bg-white" id="faq">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Can’t find the answer you’re looking for? Reach out to our{' '}
              <Link
                href="/contact-us"
                className="hover:text-primary-500 font-semibold text-primary"
              >
                customer support
              </Link>{' '}
              team.
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-6">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question}>
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">
                            {faq.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusSmallIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
}
