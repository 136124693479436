import Image from 'next/image';

import { Button } from '@components/Button';
import { Container } from '@components/Container';
import backgroundImage from 'composeiq/images/background-call-to-action.jpg';

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <Image
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
        unoptimized
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Take Your Writing to the Next Level
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Start your free trial today and experience the power of AI-based
            writing assistance.
          </p>
          <Button
            href="/register"
            rounded
            color="secondary"
            size="lg"
            className="mt-6"
          >
            Get started for free
          </Button>
        </div>
      </Container>
    </section>
  );
}
