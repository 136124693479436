import {
  CloudIcon,
  CodeBracketSquareIcon,
  CommandLineIcon,
  UserPlusIcon,
  ChartBarIcon,
  MoonIcon,
} from '@heroicons/react/20/solid';
import Image from 'next/image';
import screenshotBlockCommands from 'composeiq/images/screenshots/block-commands.png';
import screenshotOverview from 'composeiq/images/screenshots/overview.png';

const features = [
  {
    name: 'Analyze Document with AI.',
    description:
      'Our AI-powered document editor analyzes your content for readability, keywords, and more, helping you create the best possible document.',
    icon: ChartBarIcon,
  },
  {
    name: 'Teams, workspaces and project support.',
    description:
      'Work on multiple projects at once, so you can stay organized and efficient',
    icon: UserPlusIcon,
  },
  {
    name: 'Collaborative editing.',
    description: `Work together on the same document with real time syncing.`,
    icon: CloudIcon,
  },
  {
    name: 'Code, LaTeX and Diagrams.',
    description:
      'Our feature rich document editor lets you write code with syntax highlighting, math equations and even diagrams.',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'Block based editor with keyboard shortcuts.',
    description:
      'Use commands to perform a variety of actions and stay in the flow of your writing.',
    icon: CommandLineIcon,
  },
  {
    name: 'Dark mode support.',
    description:
      'Switch to dark mode with just a click and focus on your writing, without any distractions.',
    icon: MoonIcon,
  },
];

export default function SecondaryFeaturesA() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Everything you need
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Start writing with superpowers.
          </p>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">

          </p> */}
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <Image
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            src={screenshotOverview}
            alt="App screenshot"
            priority
            width={2432}
            height={1442}
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-14">
              <dt className="inline font-semibold text-gray-900">
                <feature.icon
                  className="absolute left-1 top-1 h-10 w-10"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
